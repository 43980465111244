import React from 'react'
import styled from 'styled-components'
import Link from 'components/Link'
import Image from 'components/Image'
import { color, mediaQuery } from 'utils/style'

const Background = styled.div`
  background-color: ${color('color')};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 100%;
  /* prettier-ignore */
  /*transform: matrix3d(0.9, -0.1, 0, ,
                    0.1, 0.9, 0, ,
                    0, 0, 1, 0,
                    0, 0, 0, 1);
  transform-origin: 0px 0px 0px;*/
  transform-origin: top left;
  transform: rotateZ(-5deg) scale(1.07) translateX(-6.2%);
  opacity: 0.9;
  z-index: 601;
`
const Container = styled.div`
  position: relative;
  ${mediaQuery()}
  height: fit-content;
  z-index: 600;
`
const ImgWrapper = styled.div`
  position: relative;
  flex: 1;
  margin: 16px;
`

const Content = styled.div`
  h5,
  p {
    color: ${color('contrast')};
  }
  position: relative;
  padding: 16px;
  top: 0;
  left: 0;
  z-index: 602;
`

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export default function StoryblokTileOverlayText ({ data, dimensions }) {
  return (
    <Link
      link={data.link}
      gtmEvent={data.gtmEvent}
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%'
      }}
    >
      <Wrapper>
        <ImgWrapper>
          <Image
            style={{ position: 'absolute' }}
            src={data.image}
            alt={data.imageAlt}
            title={data.imageTitle}
            description={data.imageDescription}
          />
        </ImgWrapper>
        <Container
          media={device =>
            (dimensions(device)?.w > 8
              ? 'width: 61.8%;'
              : 'width: 100%;')
          }
        >
          <Content color={data.overlayColor}>
            <h5>{data.title}</h5>
            <p>{data.subtitle}</p>
          </Content>
          <Background color={data.overlayColor} />
        </Container>
      </Wrapper>
    </Link>
  )
}
